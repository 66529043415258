// import React from 'react'
import { Link } from 'react-router-dom';

// import  { useEffect } from 'react';
// import $ from 'jquery';
// import 'meanmenu';  // Ensure you've imported the plugin if needed

//  export const Mobiletogel = () => {
//   useEffect(() => {
//     // Ensure the jQuery plugin is initialized after the component mounts
//     $('.menu-wrap .mainmenu').meanmenu({
//       meanScreenWidth: "991",
//       meanMenuContainer: 'div.mobile-menu',
//       meanRevealPosition: 'left'
//     });
//   }, []);  // Empty dependency array ensures this runs only after the component mounts

//   return (
//     <div>
//       <div className="menu-wrap">
//         <ul className="mainmenu">
//           {/* Your menu items */}
//         </ul>
//       </div>
//       <div className="mobile-menu"></div>
//     </div>
//   );
// };


import 'jquery.nicescroll';
export
    const ScrollToTopButton = () => {
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        return (
            <button
                id="scroll-top"
                className="scroll-to-top"
                onClick={scrollToTop}
            >
                <i class="ti-angle-up"></i>
            </button>
        );
    };

const Header = () => {
    return (
        <div>
            <>
                <header id="header" className="header sticky-header">
                    <div className="top-header bg-dark">
                        <div className="container">
                            <div className="col-md-6">
                                <ul className="top-info">
                                    <li>
                                        <a href="tel:07011880850">
                                            <i className="ti-mobile" />
                                            +91-7011880850{" "}
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:headlinesbihar18@gmail.com">
                                            <i className="ti-email" />
                                            headlinesbihar18@gmail.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 text-right sm-text-left">
                                <ul className="top-social">
                                    <li>
                                        <a href="https://www.facebook.com/headlinesbihar">
                                            <i className="ti-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://x.com/headlinesbihar">
                                            <i className="ti-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/@headlinesbihar">
                                            <i className="ti-youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/headlines.bihar/">
                                            <i className="ti-instagram" />
                                        </a>
                                    </li>
                                    <li className="fz-13"> Agust , 2024</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* /.top-header */}
                    <div className="mid-header">
                        <div className="container">
                            <div className="site-branding full-left">
                                <Link to="./"> <img
                                    src="img/logo/Headlines-Nation-news.png"
                                    style={{ maxWidth: 162 }}
                                    className="img-responsive"
                                    alt="headlinesnation"
                                /></Link>
                            </div>
                            {/* /.site-branding */}
                            <div className="banner pull-right">
                                <a >
                                    <img src="img/banner.jpg" alt="News24 Banner" />
                                </a>
                            </div>
                            {/* /.banner */}
                        </div>
                    </div>
                    {/* /.mid-header */}
                    <div className="mainmenu-wrap bg-red">
                        <div className="container">
                            <div className="col-md-9 col-xs-6">
                                <div className="menu-wrap mean-menu">
                                    <nav className="mainmenu">
                                        <ul id="navi-menu">
                                            {/* <li class="current_page_item"><a href="index.html"> न्यूज़</a></li> */}
                                            <li>
                                                <a >राज्य</a>
                                                <ul>
                                                    <li>
                                                        <Link to='/bihar'>बिहार</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                {/* <a href="#!"></a> */}
                                                <Link to="/politics">पॉलिटिक्स</Link>
                                            </li>

                                            <li>

                                                <Link to="/national">राष्ट्रीय</Link>
                                            </li>
                                            <li>
                                                <Link to="/crime">क्राइम</Link>
                                            </li>
                                            <li>
                                                <Link to="/health">हेल्थ</Link>
                                            </li>

                                            <li>
                                                <Link to="/sport">खेल</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <form action="#" className="search-form">
                                    <input type="text" placeholder="Search news.." />
                                    <button className="submit" type="submit">
                                        <i className="ti-search" />
                                    </button>
                                </form>
                            </div>
                            <div className="mobile-menu" />
                        </div>
                    </div>
                    {/* /.mainmenu-wrap */}
                </header>
                {/* /.header */}

                {/* <a data-scroll href="./" id="scroll-top" class="scroll-to-top"><i class="ti-angle-up"></i></a> */}
                {/* <Link to="./"  id="scroll-top" class="scroll-to-top"><i class="ti-angle-up"></i></Link> */}
                <ScrollToTopButton></ScrollToTopButton>
            </>


        </div>
    )
}

export default Header;